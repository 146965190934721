import React from 'react';
import { Container, Typography, Grid, Card, CardContent, Avatar } from '@mui/material';

const teamMembers = [
  //{ name: 'Kebin Wang', 
  //  role: 'Founder & Software Engineer', 
  //  email: 'kebinwang@gmail.com' },
  { name: 'Samuel Wang', 
    role: 'Founder & Software Engineer', 
    email: '' },
  { name: 'Alex Wang', 
    role: 'Founder & Software Engineer', 
    email: '' },
];

const TeamIntroduction: React.FC = () => {
  return (
    <Container maxWidth="md" sx={{ mt: 4 }}>
      <Typography variant="h4" gutterBottom>
        Our Team
      </Typography>
      <Grid container spacing={3}>
        {teamMembers.map((member) => (
          <Grid item xs={12} sm={4} key={member.name}>
            <Card>
              <CardContent>
                <Avatar sx={{ width: 60, height: 60, mb: 2 }}>{member.name[0]}</Avatar>
                <Typography variant="h6">{member.name}</Typography>
                <Typography color="textSecondary">{member.role}</Typography>
                <Typography color="textSecondary"><a href={`mailto:${member.email}`}>contact</a></Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default TeamIntroduction;