import React, { useState } from 'react';
import './App.css';
import Home from './components/Home';
import TeamIntroduction from './components/TeamIntroduction';
import Navigation from './components/Navigation';
import { AppBar, Toolbar, Button } from '@mui/material';

function App() {
  const [currentPage, setCurrentPage] = useState<'home' | 'teamintroduction'>('home');

  return (
    <div className="App">
      <AppBar position="static">
        <Toolbar>
          <Button color="inherit" variant="outlined" sx={{ml: 2, borderRadius: 2}} onClick={() => setCurrentPage('home')}>
            Home
          </Button>
          <Button color="inherit" variant="outlined" sx={{ml: 2, borderRadius: 2}} onClick={() => setCurrentPage('teamintroduction')}>
            Team
          </Button>
        </Toolbar>
      </AppBar>
      {currentPage === 'home' ? <Home /> : <TeamIntroduction />}
    </div>
  );
}

export default App;
