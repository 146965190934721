import React from 'react';
import { Container, Typography, Card, CardMedia, Grid, Box } from '@mui/material';

const Home: React.FC = () => {
    return (
      <Container maxWidth="md" sx={{ mt: 4 }}>
        <Grid container spacing={3}>
          <Grid xs={12} md={6}>
            <Card style={{ border: "none", boxShadow: "none" }}>
              <CardMedia
                component="img"
                height="450"
                image="pics/image1.jpg"
                alt="Image 1"
                sx={{ objectFit: "contain" }}
              />
            </Card>
          </Grid>
          <Grid xs={12} md={6}>
            <Card>
              <CardMedia
                component="img"
                height="450"
                image="/pics/image2.jpg"
                alt="Image 2"
              />
            </Card>
          </Grid>
        </Grid>
        <Box sx={{ mt: 3 }}>
        <Typography variant="body1" align="left" paragraph>
            Autism is considered a disability. One common sympton of autism is difficulty in social communication and
                 interaction, and nonverbal communication. They try to learn their own way, which, unfortunatly,
                 does not work well with the current education system.  
        </Typography>
        <Typography variant="body1" align="left" paragraph>
        With the emergence of AI, we are trying to use AI to help them. Specifically, we are trying to build an 
                 AI-powered learning assistant that can help their communication and interaction skills.         
        </Typography>
        <Typography variant="body1" align="left">
        This work is still in its really early stage. Join us in our mission to make mental health care accessible, approachable, and effective for everyone. Your well-being is our priority, and we're here to support you every step of the way.
        </Typography>
      </Box>
      </Container>
    );
  };


export default Home;

            //  Autistic person talks in their own way, which only close relatives can understand. 
            // We gather voices of individuals with autism, use AI to find its characteristics,, and to translate into 
